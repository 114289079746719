import * as React from 'react';
import analytics, { page, track, trackError } from './src/analytics';
import { ReactNode, useEffect, useRef } from 'react';
import type { GatsbyBrowser } from 'gatsby';
import { Analytics } from 'analytics';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorPage from './src/components/ErrorPage';

function Wrapper({ children }: React.PropsWithChildren<{}>) {
  // Track first page interaction
  const trackedInteraciton = useRef(false);
  useEffect(() => {
    const handleInteraction = (event: MouseEvent | TouchEvent) => {
      if (!trackedInteraciton.current) {
        track('Page First Interaction', {
          path: window.location.pathname,
          search: window.location.search,
          hash: window.location.hash,
          eventType: event.type,
        });
        trackedInteraciton.current = true;
      }
    };
    const events = ['mousemove', 'click', 'touchstart'] as const;
    events.forEach(event => {
      document.addEventListener(event, handleInteraction);
    });
    return () => {
      events.forEach(event => {
        document.removeEventListener(event, handleInteraction);
      });
    };
  }, []);
  return (
    <ErrorBoundary
      fallbackRender={({ error }) => (
        <ErrorPage title="Sorry an error occurred on this page" details={error.message} />
      )}
      onError={trackError}
    >
      {children}
    </ErrorBoundary>
  );
}

export const wrapRootElement = ({ element }) => {
  return <Wrapper>{element}</Wrapper>;
};
// On route update, set a timeout on page linger
// This keeps it in sync with https://github.com/DavidWells/analytics/blob/master/packages/gatsby-plugin-analytics/gatsby-browser.js
// which setups the page tracking
export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = (
  { location, prevLocation },
  options,
) => {
  const initPath = location.pathname;
  setTimeout(() => {
    const currentPath = window.location.pathname;
    if (currentPath === initPath) {
      const pageDataProperties = analytics.getState('page')?.last?.properties ?? null;
      track('Page Linger', {
        ...pageDataProperties,
      });
    }
  }, 1000 * 20);

  // Only fire when the page actually changes
  if (location.pathname !== prevLocation?.pathname) {
    page();
  }
};

if (typeof window !== 'undefined') {
  const isError = (value: any): value is Error => {
    return value && value instanceof Error;
  };
  const catchErrorEvent = (event: ErrorEvent | PromiseRejectionEvent) => {
    const error = 'reason' in event ? event.reason : event.error;
    const wasPromise = 'reason' in event;

    if (
      isError(error) && error.stack?.includes('chrome-extension:')
    ) {
      event.stopImmediatePropagation();
      return;
    }
  };

  window.addEventListener('error', catchErrorEvent);
  window.addEventListener('unhandledrejection', catchErrorEvent);
}
