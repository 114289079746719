import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { navigate } from 'gatsby-link';
import DocsPageLayout from '../components/DocsPageLayout';
import HeaderMeta from '../components/HeaderMeta';
import { submitForm } from '../lib/submitForm';

import {
  Button,
  Documentation,
  EmailSubmitForm,
  FlexColumn,
  FlexRow,
  HeadingBox,
  responsiveBreakpoints,
  ResponsiveMaxWidthFrame,
  TocLinks,
} from '@minware/ui-minware';
import { track } from '../analytics';

const headingTrackConfig = {
  '/docs/demo': 'Demo - Header Reached',
}

const twoColBreakpoint = responsiveBreakpoints.multiCol;
export const DocsPageTemplate = ({
  content,
  post,
  pathname,
  links,
  includeContact,
  hideToc,
}) => {
  const headerWithCanon = {
    ...content,
    canonicalUrl: pathname,
    title: `${content.title} | minware`,
  };

  const [formState, setFormState] = useState({});

  const timeoutRef = useRef();

  useTrackHeadings(pathname in headingTrackConfig, post.headings, headingTrackConfig[pathname]);

  // Track changes to the form state after 500ms of no change
  useEffect(() => {
    if (Object.keys(formState).length) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        timeoutRef.current = null;
        track('Docs Page - Form Change', {
          formState,
        });
      }, 500);
    }
  }, [formState]);

  const handleFocus = useCallback(e => {
    track(`Docs Page - Form Focus - ${e.target.name}`, {
      value: e.target.value,
    });
  }, []);

  const handleBlur = useCallback(e => {
    track(`Docs Page - Form Blur - ${e.target.name}`, {
      value: e.target.value,
    });
  }, []);

  const handleChange = useCallback(e => {
    const formData = new FormData(e.target.form);
    setFormState(Object.fromEntries(formData.entries()));
  }, []);

  const confirmPage = '/contact/thanks-demo/';

  const handleSubmit = e => {
    const formData = new FormData(e.target);
    track('Docs Page - Form Submit', {
      formState: Object.fromEntries(formData.entries()),
    });
    e.preventDefault();
    submitForm(formData, () => {
      navigate(confirmPage);
    });
  };

  const tocLinks = <TocLinks links={links} currentUrl={pathname} breakpoint={twoColBreakpoint} />;

  return (
    <DocsPageLayout
      desktopBgImageName="none"
      leftMenu={hideToc ? <div style={{ width: '110px' }} /> : tocLinks}
    >
      <HeaderMeta header={headerWithCanon} />
      <FlexRow justifyContent="center" fullWidth>
        <ResponsiveMaxWidthFrame maxWidth="780px">
          <Documentation links={links} currentUrl={pathname} body={post.html} />
        </ResponsiveMaxWidthFrame>
      </FlexRow>
      {!includeContact ? null : (
        <ResponsiveMaxWidthFrame justifyContent="center">
          <FlexColumn gap={0}>
            <HeadingBox
              tagLevel="h2"
              align="center"
              justifyContent="center"
              forceMargin="20px 0 10px 0"
            >
              Ready to get started?
            </HeadingBox>
            <Button cta link="/api/auth/login?signup=1" fullWidth>
              Sign Up Free
            </Button>
            <EmailSubmitForm
              buttonLabel="Talk to Us"
              name="demo"
              method="post"
              action={confirmPage}
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={handleSubmit}
              onEmailChange={handleChange}
              handleFocus={handleFocus}
              handleBlur={handleBlur}
              isOutline
            >
              <input type="hidden" name="form-name" value="demo" />
              <input type="hidden" name="location" value="docs" />
              <input type="hidden" name="page" value={pathname} />
              <div hidden>
                <label>
                  Don't fill this out: <input name="bot-field" onChange={handleChange} />
                </label>
              </div>
            </EmailSubmitForm>
          </FlexColumn>
        </ResponsiveMaxWidthFrame>
      )}
    </DocsPageLayout>
  );
};

DocsPageTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
  includeContact: PropTypes.bool,
  hideToc: PropTypes.bool,
};

const buildHeadingLinks = (pageLink, headings) => {
  const baseList = [];
  const itemStack = [[1, baseList]];
  const processLinks = heading => {
    const headingDepth = heading.depth;
    let [checkDepth, checkList] = itemStack.at(-1);
    while (headingDepth <= checkDepth) {
      itemStack.pop();
      [checkDepth, checkList] = itemStack.at(-1);
    }
    const newItem = {
      id: heading.id,
      title: heading.value,
      link: `${pageLink}#${heading.id}`,
      parentLink: pageLink,
      children: [],
    };
    checkList.push(newItem);
    checkDepth = headingDepth;
    itemStack.push([checkDepth, newItem.children]);
  };
  headings.filter(({ depth }) => depth !== 1).forEach(processLinks);

  return baseList;
};

function buildLinkList(all, pathName) {
  // Extract flat list of item data
  const itemList = all.edges.map(edge => {
    const rv = {
      link: edge.node.fields.slug.replace(/\/$/, ''),
      parentLink: edge.node.frontmatter.parentSlug ? `/${edge.node.frontmatter.parentSlug}` : '/',
      title: edge.node.frontmatter.tocTitle ?? edge.node.frontmatter.title,
      order: edge.node.frontmatter.order ?? 0,
      children: [],
      headings: [],
    };
    if (pathName === rv.link) {
      rv.headings = buildHeadingLinks(rv.link, edge.node.headings);
    }

    return rv;
  });

  // Add root item
  itemList.push({
    link: '/',
    parentLink: null,
    children: [],
    headings: [],
  });
  // Add children to their parents to create a tree
  const itemMap = new Map();
  itemList.forEach(item => {
    itemMap.set(item.link, item);
  });
  itemList.forEach(item => {
    const { parentLink } = item;
    if (!parentLink) {
      return;
    }
    const parentItem = itemMap.get(parentLink);
    if (!parentItem) {
      throw new Error(`Parent link not found in docs: ${parentLink}, ${item.link}`);
    }
    parentItem.headings.length = 0;
    parentItem.children.push(item);
    // Sort the children of each item
    parentItem.children.sort((a, b) => a.order - b.order);
  });
  // Return the root of the tree
  return itemMap.get('/').children;
}

const DocsPage = ({ data, location }) => {
  const { post, all } = data;
  const { pathname } = location;
  const stripSlashPath = pathname.length > 0 && pathname[pathname.length - 1] === '/'
    ? pathname.substr(0, pathname.length - 1)
    : pathname;

  const links = buildLinkList(all, stripSlashPath);

  const content = post.frontmatter;

  return (
    <DocsPageTemplate
      content={content}
      post={post}
      pathname={stripSlashPath}
      links={links}
      includeContact={content.includeContact}
      hideToc={content.hideToc}
    />
  );
};

DocsPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default DocsPage;

export const pageQuery = graphql`
  query DocsPageByID($id: String!) {
    post: markdownRemark(id: { eq: $id }) {
      id
      html
      headings {
        id
        value
        depth
      }
      frontmatter {
        title
        description
        includeContact
        hideToc
      }
    }

    all: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "docs-page" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          headings {
            id
            value
            depth
          }
          frontmatter {
            tocTitle
            parentSlug
            order
          }
        }
      }
    }
  }
`

const useTrackHeadings = (enabled, headings, eventName) => {
  useEffect(() => {
    if (!enabled) {
      return;
    }
    // Object to keep track of which headers have been tracked.
    const trackedHeaders = {};

    function trackHeaderReached(headerId, headerTitle, cause) {
      if (trackedHeaders[headerId] || !headerId) {
        return;
      }
      trackedHeaders[headerId] = true;
      track(eventName, {
        headerId,
        headerTitle,
        cause,
      });
    }

    // Click event handler for anchor links jumping to headers.
    function handleClick(event) {
      // Extract the header ID from the href attribute (e.g., "#header-id")
      const headerId = event.target.getAttribute('href').replace('#', '');
      const headerTitle = event.target.textContent;
      trackHeaderReached(headerId, headerTitle, 'click');
    }

    // Create an IntersectionObserver to watch header elements.
    const observer = new IntersectionObserver((entries, observerInstance) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const headerId = entry.target.id;
          const headerTitle = entry.target.textContent;
          trackHeaderReached(headerId, headerTitle, 'scroll');
          // Remove from the observer once it has been tracked.
          observerInstance.unobserve(entry.target);
        }
      });
    }, { threshold: 1.0 });

    const links = [];
    headings.filter(h => h.depth === 2).forEach(header => {
      // Find the link that targets this header.
      const link = document.querySelector(`a[href="#${header.id}"]`);
      const headerEl = document.getElementById(header.id);
      if (link) {
        // Add the link to the list of links to be tracked.
        link.addEventListener('click', handleClick);
        links.push(link);
      }
      // Check if the header is fully in view and add it to the tracking map if it is so we dont
      // track it again.
      const rect = headerEl.getBoundingClientRect();
      if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
        trackedHeaders[header.id] = true;
      } else {
        observer.observe(headerEl);
      }
    });

    // Cleanup function to remove event listeners and disconnect the observer.
    return () => {
      links.forEach(el => {
        el.removeEventListener('click', handleClick);
      });
      observer.disconnect();
    };
  }, [enabled, headings, eventName]);
}
