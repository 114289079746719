import React, { useState, useCallback, useEffect, useRef } from 'react'
import { graphql } from 'gatsby';
import { navigate } from 'gatsby-link'
import Layout from '../components/Layout'
import HeaderMeta from '../components/HeaderMeta';
import { track } from '../analytics';
import { submitForm } from '../lib/submitForm';

import {
  ResponsiveMaxWidthFrame,
  SectionGroup,
  PageNameBox,
  TitleBox,
  Form,
  FormInput,
  FormSubmit,
  BodyBox,
} from "@minware/ui-minware";

export const WorkingAgreementPageTemplate = ({
  content,
  confirmPage,
  handleSubmit,
  formName,
  handleChange,
}) => {
  const headerWithCanon = {
    ...content?.header,
    canonicalUrl: '/working-agreement',
  };

  return (
    <Layout>
      <HeaderMeta header={headerWithCanon}/>

      <ResponsiveMaxWidthFrame maxWidth="500px">
        <SectionGroup>
          <PageNameBox>{content.pageName}</PageNameBox>
          <TitleBox>
            {content.title}
          </TitleBox>
          <BodyBox align="center">
            {content.description}
          </BodyBox>
        </SectionGroup>
        <Form
          name="workingAgreement"
          method="post"
          action={confirmPage}
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={handleSubmit}
          onChange={handleChange}
        >
          {/* The `form-name` hidden field is required to support form submissions without
              JavaScript */}
          <input type="hidden" name="form-name" value={formName} />
          <div hidden>
            <label>
              Don't fill this out:{' '}
              <input name="bot-field" onChange={handleChange} />
            </label>
          </div>

          <FormInput
            name="name"
            type="text"
            placeholder={content.placeholders.name}
            required={true}
          />
          <FormInput
            name="email"
            type="email"
            placeholder={content.placeholders.email}
            required={true}
          />
          <FormInput
            name="organization"
            type="text"
            placeholder={content.placeholders.organization}
          />
          <div>{' ' /* Spacing hack */}</div>
          <FormSubmit name="submit" value={content.submitText}/>
        </Form>
      </ResponsiveMaxWidthFrame>
    </Layout>
  );
}

const WorkingAgreementPage = (props) => {
  const { data } = props;
  const { main } = data;

  const formName = 'workingAgreement';
  const [formState, setFormState] = useState({});
  const timeoutRef = useRef();

  const handleChange = useCallback((e) => {
    const formData = new FormData(e.target.form);
    setFormState(Object.fromEntries(formData.entries()));
  }, []);

   // Track changes to the form state after 500ms of no change
   useEffect(() => {
    if (Object.keys(formState).length) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        timeoutRef.current = null;
        track('Working Agreement Page - Form Change', {
          formState
        })
      }, 500);
    }
  }, [formState]);

  const handleFocus = useCallback((e) => {
    track(`Working Agreement Page - Form Focus - ${e.target.name}`, {
      value: e.target.value,
    });
  }, []);

  const handleBlur = useCallback((e) => {
    track(`Working Agreement Page - Form Blur - ${e.target.name}`, {
      value: e.target.value,
    });
  }, []);

  const confirmPage = '/working-agreement/submitted/';

  const handleSubmit = useCallback((e) => {
    const formData = new FormData(e.target);
    track('Working Agreesment Page - Form Submit', {
      formState: Object.fromEntries(formData.entries()),
    });
    e.preventDefault();
    submitForm(formData, () => { navigate(confirmPage)});
  }, []);

  const content = main.frontmatter;

  return (
    <WorkingAgreementPageTemplate
      content={content}
      confirmPage={confirmPage}
      handleSubmit={handleSubmit}
      formName={formName}
      handleChange={handleChange}
      handleFocus={handleFocus}
      handleBlur={handleBlur}
    />
  )
};

export default WorkingAgreementPage;


export const workingAgreementPageQuery = graphql`
  query WorkingAgreementPage($id: String!) {
    main: markdownRemark(id: { eq: $id }) {
      ...HeaderMeta
      frontmatter {
        pageName
        title
        description
        placeholders {
          name
          email
          organization
          message
        }
        submitText
      }
    }
  }
`;
