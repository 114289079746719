import React, { useEffect } from 'react';
import { useLocation } from "@reach/router"
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import { page } from '../analytics';

function toFullUrl(partialUrl) {
  return partialUrl.indexOf('http') === 0 ? partialUrl
  : `https://www.minware.com${partialUrl && partialUrl[0] !== '/' ? '/' : ''}${partialUrl}`;
}

const HeaderMeta = ({ header }) => {
  // WARNING: this must be kept in sync with app, which hosts survey pre-auth pages
  const imageLink = toFullUrl(header.imageLink ?? 'img/og-image.png');
  const canonicalUrl = header.canonicalUrl ? toFullUrl(header.canonicalUrl) : null; // For 404s, etc.
  const ogType = header.ogType ?? 'website';
  const ogTitle = header.title.replace(' | minware', '');

  return (
    <Helmet
      defer={false}
    >
      <title>{header.title}</title>
      <meta
        name="description"
        content={header.description}
      />
      <link rel="canonical" href={canonicalUrl} />
      <meta property="og:type" content={ogType} />
      <meta property="og:title" content={ogTitle} />
      <meta property="og:description" content={header.description} />
      {canonicalUrl && <meta property="og:url" content={canonicalUrl} />}
      <meta
        property="og:image"
        content={imageLink}
      />
    </Helmet>
  )
}

export default HeaderMeta;

export const query = graphql`
  fragment HeaderMeta on MarkdownRemark {
    frontmatter {
      header {
        title
        description
      }
    }
  }
`;
