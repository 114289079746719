import React, { useState, useCallback, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import HeaderMeta from '../components/HeaderMeta';
import { navigate } from 'gatsby-link'
import { submitForm } from '../lib/submitForm';

import {
  HeadingBox,
  ResponsiveMaxWidthFrame,
  SectionGroup,
  TitleBox,
  SubHeadlineBox,
  Button,
  ResponsiveCardList,
  ResponsivePriceCard,
  HorizontalDivider,
  FlexSpacer,
  EmailSubmitForm,
  HtmlBody
} from "@minware/ui-minware";
import { track } from '../analytics';

const wideBreakpoint = 1040;

export const PricingPageTemplate = ({
  header,
  body,
  confirmDemoPage,
  handleSubmit,
  handleChange,
  handleFocus,
  handleBlur,
}) => {
  const headerWithCanon = {
    ...header,
    canonicalUrl: '/pricing',
  }

  return (
    <Layout>
      <HeaderMeta header={headerWithCanon}/>

      <ResponsiveMaxWidthFrame maxWidth="600px" spacing={20}>
        <SectionGroup>
          <TitleBox>Pricing</TitleBox>
          <SubHeadlineBox centered isMain>
            Choose the right plan for your organization.
          </SubHeadlineBox>
        </SectionGroup>
      </ResponsiveMaxWidthFrame>

      <ResponsiveMaxWidthFrame maxWidth="1040px" spacing={40}>
        <ResponsiveCardList breakpoint={wideBreakpoint} isThreeColumn>
          <ResponsivePriceCard>
            <HeadingBox level="h2" tagLevel="h3" align="center" justifyContent="center" evenMargin>
              Free
            </HeadingBox>
            <HeadingBox level="h0" tagLevel="div" align="center" justifyContent="center">
              $0
            </HeadingBox>
            <SubHeadlineBox centered>1-5 contributors</SubHeadlineBox>
            <HorizontalDivider isGrayBg />
            <p>All core features:</p>
            <ul>
              <li>Unlimited repositories and projects</li>
              <li>Unlimited historical data backfill</li>
              <li>Unlimited minware report viewers</li>
              <li>Unlimited dashboards</li>
              <li>Access to all minware reports</li>
              <li>Email-based support</li>
            </ul>
            <FlexSpacer />
            <Button cta link="/api/auth/login?signup=1" fullWidth>
              Sign Up
            </Button>
          </ResponsivePriceCard>
          <ResponsivePriceCard>
            <HeadingBox level="h2" tagLevel="h3" align="center" justifyContent="center" evenMargin>
              Business
            </HeadingBox>
            <HeadingBox level="h0" tagLevel="div" align="center" justifyContent="center">
              $25
            </HeadingBox>
            <SubHeadlineBox centered>Per contributor per month</SubHeadlineBox>
            <HorizontalDivider isGrayBg />
            <p>
              Everything in <b>Free</b>, plus:
            </p>
            <ul>
              <li>Team-level reporting</li>
              <li>Report data exporting</li>
              <li>Cost capitalization</li>
              <li>Phone and Slack support</li>
              <li>Dedicated success agent</li>
              <li>Fast support response times</li>
            </ul>
            <FlexSpacer />
            <Button cta link="/api/auth/login?signup=1" fullWidth>
              Try Free
            </Button>
          </ResponsivePriceCard>
          <ResponsivePriceCard>
            <HeadingBox level="h2" tagLevel="h3" align="center" justifyContent="center" evenMargin>
              Enterprise
            </HeadingBox>
            <HeadingBox level="h0" tagLevel="div" align="center" justifyContent="center">
              Custom
            </HeadingBox>
            <SubHeadlineBox centered>Monthly or annual billing</SubHeadlineBox>
            <HorizontalDivider isGrayBg />
            <p>
              Everything in <b>Business</b>, plus:
            </p>
            <ul>
              <li>Full org-chart reporting</li>
              <li>Role-based access control</li>
              <li>SSO w/SAML</li>
              <li>On-premise ingest agent</li>
              <li>Enterprise customer success</li>
              <li>Custom board deck support</li>
            </ul>
            <FlexSpacer />
            <EmailSubmitForm
              buttonLabel="Get Quote"
              isGrayBackground
              lrPadPx={10}
              name="demo"
              method="post"
              action={confirmDemoPage}
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={(e) => handleSubmit(e, 'pricingEnterprise')}
              onEmailChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
            >
              <input type="hidden" name="form-name" value="demo" />
              <input type="hidden" name="location" value="pricing" />
              <input type="hidden" name="page" value="/pricing" />
              <div hidden>
                <label>
                  Don't fill this out:{' '}
                  <input name="bot-field" onChange={handleChange} />
                </label>
              </div>
            </EmailSubmitForm>
          </ResponsivePriceCard>
        </ResponsiveCardList>
      </ResponsiveMaxWidthFrame>

      <ResponsiveMaxWidthFrame maxWidth="640px" spacing={20}>
        <HeadingBox
          level="h0"
          tagLevel="h2"
          align="center"
          justifyContent="center"
          forceMargin="60px 0 10px 0"
        >
          Frequently Asked Questions
        </HeadingBox>
        <HtmlBody noListIndent={true} children={body}/>
      </ResponsiveMaxWidthFrame>
    </Layout>
  )
}

PricingPageTemplate.propTypes = {
  pretitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const PricingPage = ({ data, location }) => {
  const { main } = data;

  const [formState, setFormState] = useState({});

  const timeoutRef = useRef();
  // Track changes to the form state after 500ms of no change
  useEffect(() => {
    if (Object.keys(formState).length) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        timeoutRef.current = null;
        track('Pricing Page - Form Change', {
          formState
        })
      }, 500);
    }
  }, [formState]);

  const handleChange = useCallback((e) => {
    const formData = new FormData(e.target.form);
    setFormState(Object.fromEntries(formData.entries()));
  }, []);

  const handleFocus = useCallback((e) => {
    track(`Pricing Page - Form Focus - ${e.target.name}`, {
      value: e.target.value,
    });
  }, []);

  const handleBlur = useCallback((e) => {
    track(`Pricing Page - Form Blur - ${e.target.name}`, {
      value: e.target.value,
    });
  }, []);

  const confirmDemoPage = '/contact/thanks-demo/';

  const handleSubmit = (e) => {
    const formData = new FormData(e.target);
    track('Pricing Page - Form Submit', {
      formState: Object.fromEntries(formData.entries()),
    });
    e.preventDefault()
    submitForm(formData, () => { navigate(confirmDemoPage)});
  }


  return (
    <PricingPageTemplate
      header={main.frontmatter.header}
      body={main.html}
      confirmDemoPage={confirmDemoPage}
      handleSubmit={handleSubmit}
      handleChange={handleChange}
      handleFocus={handleFocus}
      handleBlur={handleBlur}
    />
  )
}

PricingPage.propTypes = {
  data: PropTypes.object.isRequired,
}


export default PricingPage;

export const pricingPageQuery = graphql`
  query PricingPage($id: String!) {
    main: markdownRemark(id: { eq: $id }) {
      ...HeaderMeta
      html
    }
  }
`
