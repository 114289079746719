import { ColorList } from "@minware/ui-minware";

import { vectors } from "@minware/ui-minware";

const wideBreakpoint = 800;

const inputLogos = [
  {
    label: 'GitHub',
    vector: vectors.githubLogo,
  },
  {
    label: 'GitLab',
    vector: vectors.gitlabLogo,
  },
  {
    label: 'Azure',
    vector: vectors.azureDevopsLogo,
  },
  /*
  {
    label: 'Bitbucket',
    vector: vectors.bitbucketLogo,
  },
  */
  {
    label: 'Jira',
    vector: vectors.jiraLogo,
  },
  {
    label: 'Google',
    vector: vectors.googleCalendarLogo,
  },
  {
    label: '& More',
    vector: vectors.ellipsis,
  },
];

const stackItems = [
  {
    label: 'Data Extraction & Quality',
    vector: vectors.check,
    color: ColorList.green100,
  },
  {
    label: 'Data Mapping & Linking',
    vector: vectors.graphLink,
    color: ColorList.cyan100,
  },
  {
    label: 'Change History',
    vector: vectors.historyCircle,
    color: ColorList.purple100,
  },
  {
    label: 'minQL Query Engine',
    vector: vectors.codeSlash,
    color: ColorList.magenta100,
  },
];
const outputReports = [
  {
    label: 'Report Library',
    vector: vectors.book,
  },
  {
    label: 'Custom Reports',
    vector: vectors.lineChart,
  },
  {
    label: 'Scorecard & Benchmarks',
    vector: vectors.trophy,
  },
  {
    label: 'Shareable Dashboards',
    vector: vectors.dashboard,
  },
];

const octopusProps = {
  inputLogos,
  stackItems,
  outputReports,
  wideBreakpoint,
  bottomText: 'Data Modeling Platform',
};

export { octopusProps };
