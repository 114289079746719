import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { APP_ENV } from "gatsby-env-variables"

import Layout from '../components/Layout';
import HeaderMeta from '../components/HeaderMeta';
import { octopusProps } from '../lib/octopusProps';
import {
  BasicCard,
  BasicGrid,
  BodyBox,
  Button,
  chartVectorBuilder,
  ColorList,
  FlexColumn,
  FlexRow,
  HeadingBox,
  LinkBox,
  MinwareOctopusStack,
  responsiveBreakpoints,
  ResponsiveComparison,
  ResponsiveComparisonParagraph,
  ResponsiveComparisonText,
  ResponsiveFigure,
  ResponsiveImage,
  ResponsiveMaxWidthFrame,
  ResponsiveTwoColumn,
  SimpleIcon,
  SectionGroup,
  SubHeadlineBox,
  TitleBox,
  vectors,
  ResponsiveTextGraphicContent,
  ResponsiveCardList,
  useFontsLoaded,
} from "@minware/ui-minware";

const {
  scorecardGraphic,
} = vectors;

const processMarkdownLinks = (body) => {
  // Extract all markdown links from body
  const links = body.match(/\[(.*?)\]\((.*?)\)/g);
  // Replace markdown links with sentinal value
  const bodyReplaced = body.replace(/\[(.*?)\]\((.*?)\)/g, (match, p1, p2) => {
    return `||`;
  });
  const bodyParts = bodyReplaced.split('||');

  // Merge list of body parts and links into single array
  const mergedParts = [];
  let index = 0;
  for (const bodyPart of bodyParts) {
    mergedParts.push(bodyPart);
    if (links[index]) {
      // Extract link text and link url from link string
      const linkText = links[index].match(/\[(.*?)\]\((.*?)\)/)[1];
      const linkUrl = links[index].match(/\[(.*?)\]\((.*?)\)/)[2];
      mergedParts.push(<LinkBox inline noFlex isBlack link={linkUrl}>{linkText}</LinkBox>);
    }
    index++;
  }

  return <>
    {mergedParts.map((part, index) => (
      <React.Fragment key={index}>
        {part}
      </React.Fragment>
    ))}
  </>;
}
/*
  for (const bodyPart of bodyParts) {
    // Add bodyPart to array of react components as text element
  }

  // Merge markdown links and body parts together with react components
  const processedBody = bodyParts.map((part, index) => {
    if (links[index]) {
      return <LinkBox inline noFlex isBlack link={links[index]}>{part}</LinkBox>;
    }
    return part;
  });

  return body.replace(/\[(.*?)\]\((.*?)\)/g, (match, p1, p2) => {
    return `<LinkBox inline noFlex isBlack link="${p2}">${p1}</LinkBox>`;
  });
};
*/

const useClientFontsLoaded = (typeof document !== 'undefined') ? useFontsLoaded : () => false;
const blankImage = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';

export const LandingPageTemplate = ({
  header,
  title,
  subheading,
  ctaText,
  bottomCtaText,
  ctaLink,
  secondCtaText,
  secondCtaLink,
  reportTitle,
  reportCtaText,
  reportCtaLink,
  minqlTitle,
  pathname,
  chartMap,
  topCharts,
  reportContents,
}) => {
  const headerWithCanon = {
    ...header,
    canonicalUrl: pathname,
  };

  const areFontsLoaded = useClientFontsLoaded();
  const chartVectorBuilderFn = useMemo(
    () => areFontsLoaded && (typeof document !== 'undefined') ? chartVectorBuilder : () => blankImage,
    [areFontsLoaded]
  );
  const isDev = APP_ENV === 'development';

  return (
    <Layout desktopBgImageName="none" gap={80}>
      <HeaderMeta header={headerWithCanon}/>

      <ResponsiveTwoColumn
        firstColWidth={0.8}
        breakpoint={responsiveBreakpoints.multiCol}
        mobileGap={40}
        forceMargin="40px 0"
      >
        <ResponsiveTextGraphicContent breakpoint={responsiveBreakpoints.multiCol} noMarginTop>
          <FlexColumn justifyContent="center">
            <SectionGroup>
              <TitleBox alignLeftDesktop>{title}</TitleBox>
              <SubHeadlineBox centered isMain alignLeftDesktop>
                {subheading}
              </SubHeadlineBox>
            </SectionGroup>
            <FlexRow gap={20} rowGap={0} justifyContent="center" alignLeftDesktop>
              <Button cta link={ctaLink}>
                {ctaText}
              </Button>
              <Button ctaSecondary link={secondCtaLink}>
                {secondCtaText}
              </Button>
            </FlexRow>
          </FlexColumn>
        </ResponsiveTextGraphicContent>
        <ResponsiveCardList breakpoint={responsiveBreakpoints.multiCol} maxWidthMobile={420}>
          {topCharts.map((chart) => (
            <ResponsiveImage
              src={chartVectorBuilderFn(chartMap[chart.key])}
              isSrcSvg
              origHeight={chart.origHeight}
              origWidth={chart.origWidth}
              title={chart.title}
              minWidth={300}
              lrPad={0}
              tbMargin={0}
            />
          ))}
        </ResponsiveCardList>
      </ResponsiveTwoColumn>

      {/* Value prop: next-gen models are good */}
      <ResponsiveMaxWidthFrame maxWidth={`${responsiveBreakpoints.multiCol}px`} marginTop={20}>
        <ResponsiveMaxWidthFrame maxWidth="640px">
          <HeadingBox
            tagLevel="h2"
            align="center"
            justifyContent="center"
            forceMargin="40px 0 20px 0"
          >
            minware insights are
          </HeadingBox>
        </ResponsiveMaxWidthFrame>
        <SectionGroup showBackground>
          <ResponsiveTwoColumn breakpoint={responsiveBreakpoints.multiCol}>
            <div>
              <HeadingBox
                level="h3"
                align="center"
                justifyContent="center"
                imageUrl={vectors.check(ColorList.blue100)}
                imageSize={24}
                isIcon
              >
                Actionable
              </HeadingBox>
              <BodyBox align="center" medium isGray>
                See exactly where problems lie so you know where to improve.
              </BodyBox>
            </div>
            <div>
              <HeadingBox
                level="h3"
                align="center"
                justifyContent="center"
                imageUrl={vectors.target(ColorList.blue100)}
                imageSize={24}
                isIcon
              >
                Impact-Focused
              </HeadingBox>
              <BodyBox align="center" medium isGray>
                Metrics aren’t an end in themselves. Use metrics to achieve goals.
              </BodyBox>
            </div>
            <div>
              <HeadingBox
                level="h3"
                align="center"
                justifyContent="center"
                imageUrl={vectors.settings(ColorList.blue100)}
                imageSize={24}
                isIcon
              >
                Automatic
              </HeadingBox>
              <BodyBox align="center" medium isGray>
              Connect your data sources and see your insights. No setup required.
              </BodyBox>
            </div>
          </ResponsiveTwoColumn>
        </SectionGroup>
      </ResponsiveMaxWidthFrame>

      {/* library reports */}
      <ResponsiveMaxWidthFrame maxWidth="1060px" spacing={20}>
        <SectionGroup>
          <HeadingBox
            tagLevel="h2"
            align="center"
            justifyContent="center"
            forceMargin="40px 0 20px 0"
          >
            {reportTitle}
          </HeadingBox>
        </SectionGroup>

        <BasicGrid gap={30} rowGap={40} minColWidth={250}>
          {reportContents.map((content) => (
            <BasicCard link={content.link} minWidth={180}>
              <ResponsiveImage
                src={content.figureKey && chartMap[content.figureKey]
                  ? chartVectorBuilderFn(chartMap[content.figureKey])
                  : content.image.childImageSharp.fluid.src}
                isSrcSvg={content.figureKey && chartMap[content.figureKey]}
                title={content.imageTitle}
                origWidth={content.origWidth}
                origHeight={content.origHeight}
                minWidth={180}
                lrPad={40}
                disableWrap={isDev}
              />
              <FlexColumn gap={5}>
                <HeadingBox level="h3" noMargin>
                  {content.heading}
                </HeadingBox>
                <BodyBox noMargin>
                  {processMarkdownLinks(content.body)}
                </BodyBox>
              </FlexColumn>
            </BasicCard>
          ))}
        </BasicGrid>
      </ResponsiveMaxWidthFrame>

      <FlexRow justifyContent="center">
        <Button cta link={reportCtaLink}>
          {reportCtaText}
        </Button>
      </FlexRow>

      {/*minQL and BI*/}
      <ResponsiveMaxWidthFrame maxWidth={`${responsiveBreakpoints.multiCol}px`}>
        <HeadingBox
          tagLevel="h2"
          align="center"
          justifyContent="center"
          forceMargin="40px 0 10px 0"
        >
          {minqlTitle}
        </HeadingBox>
        <ResponsiveComparison breakpoint={responsiveBreakpoints.multiCol} evenMarginTop reverse>
          <ResponsiveComparisonText
            leftBarColor={ColorList.magenta100}
            breakpoint={responsiveBreakpoints.multiCol}
        >
            <ResponsiveComparisonParagraph>
              All minware reports are built on top of the{'  '}
              <LinkBox inline link="/docs/overview/minql" size="body">
                minQL query language
              </LinkBox>{' '}
              and fully editable.
            </ResponsiveComparisonParagraph>
            <ResponsiveComparisonParagraph>
              Access any field from any data source to create custom metrics with powerful formulas,
              including custom event cycle times.
            </ResponsiveComparisonParagraph>
            <ResponsiveComparisonParagraph>
              Say goodbye to spreadsheets and SQL.
            </ResponsiveComparisonParagraph>
            <ResponsiveComparisonParagraph>
              <LinkBox inline noFlex link="/org/demo/dashboard/ae061875-f72c-42a6-b999-ce54d9921df2"
                size="body">See Report Builder Live &gt;</LinkBox>
            </ResponsiveComparisonParagraph>
          </ResponsiveComparisonText>
          <ResponsiveFigure
            maxWidth={1000}
            image="/img/ss-custom-report-ui-orig.png"
            aspect={1.6}
            flex={2.6}
            pad={0}
          />
        </ResponsiveComparison>
      </ResponsiveMaxWidthFrame>

      <ResponsiveMaxWidthFrame maxWidth="800px">
        <HeadingBox
          tagLevel="h2"
          align="center"
          justifyContent="center"
          forceMargin="40px 0 10px 0"
        >
          How does it work?
        </HeadingBox>
        <MinwareOctopusStack {...octopusProps} />
      </ResponsiveMaxWidthFrame>

      <ResponsiveMaxWidthFrame maxWidth="600px" spacing={20}>
        <FlexRow gap={20} rowGap={0} justifyContent="center">
          <Button cta link={ctaLink}>
            {bottomCtaText}
          </Button>
          <Button ctaSecondary link={secondCtaLink}>
            {secondCtaText}
          </Button>
        </FlexRow>
      </ResponsiveMaxWidthFrame>
    </Layout>
  )
}

const LandingPage = ({ data, location }) => {
  const { frontmatter } = data.post;
  const { allChartsJson } = data;
  const { pathname } = location;
  const stripSlashPath = pathname.length > 0 && pathname[pathname.length - 1] === '/'
    ? pathname.substr(0, pathname.length - 1)
    : pathname;


  const chartMap = allChartsJson.edges.reduce((acc, edge) => {
    acc[edge.node.key] = edge.node;
    return acc;
  }, {});

  return (
    <LandingPageTemplate
      pathname={stripSlashPath}
      {...frontmatter}
      chartMap={chartMap}
    />
  )
}

LandingPage.propTypes = {
  data: PropTypes.shape({
    post: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default LandingPage

export const pageQuery = graphql`
  query LandingPageTemplate($id: String!) {
    post: markdownRemark(id: { eq: $id }) {
      ...HeaderMeta
      frontmatter {
        title
        subheading
        subheading2
        ctaText
        bottomCtaText
        ctaLink
        secondCtaText
        secondCtaLink
        reportTitle
        reportCtaText
        reportCtaLink
        minqlTitle
        topCharts {
          key
          title
          origWidth
          origHeight
        }
        reportContents {
          link
          image {
            childImageSharp {
              fluid(maxWidth: 520) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          figureKey
          imageTitle
          origWidth
          origHeight
          heading
          body
        }
      }
    }
    allChartsJson {
      edges {
        node {
          key
          title
          type
          width
          height
          yAxis {
            title
            min
            max
            tickLabels
            dashLineAt
          }
          xAxis {
            legend {
              color
              label
              shape
            }
            tickLabels
          }
          series {
            color
            shape
            values
          }
        }
      }
    }
  }
`
