import React, { ReactNode, useEffect } from 'react';
import { BodyBox, HeadingBox, ResponsiveMaxWidthFrame } from '@minware/ui-minware';
import { track } from '../analytics';
import Layout from './Layout';

interface Props {
  hideSupportMessage?: boolean;
  title: string;
  subtitle?: string;
  children?: ReactNode;
  /**
   * center or left
   */
  align?: string;
  details?: string | string[];
}

const supportEmail = 'support@minware.com';
const supportEmailHref = `mailto:${supportEmail}`;

/**
 * This is the central error page UI should be used for displaying any type of error that
 * is encountered client-side. For example, network request failures, 404 pages, auth failures
 * react error boundary catches, etc.
 *
 * All other error related files (e.g. error.tsx, _error.tsx, ErrorBoundary.tsx) use this
 * component to display their error.
 */
export default function ErrorPage(props: Props) {
  const { title, subtitle, children, align = 'left', details } = props;

  let { hideSupportMessage } = props;

  let curTitle = title;
  let subtitleEl = subtitle ? <BodyBox>{subtitle}</BodyBox> : null;

  useEffect(() => {
    track('Error Page Shown', {
      title: curTitle,
      subtitle,
      details,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const supportMessage = !hideSupportMessage && (
    <>
      <BodyBox>This problem has been reported to our development team.</BodyBox>
      <BodyBox>
        Please try again or contact <ContactEmailLink /> if the problem continues.
      </BodyBox>
    </>
  );

  return (
    <Layout desktopBgImageName="none">
      <ResponsiveMaxWidthFrame maxWidth="500px" align={align}>
        <HeadingBox level="h2">{curTitle}</HeadingBox>
        {subtitleEl}
        {supportMessage}
        {children}
      </ResponsiveMaxWidthFrame>
    </Layout>
  );
}

export function ContactEmailLink() {
  return <a href={supportEmailHref}>{supportEmail}</a>;
}
