import { trackError } from "../analytics";

export function submitForm(formData: FormData, onSuccess: () => void) {
  return fetch('/', {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: new URLSearchParams(formData as any).toString(),
  }).then(async (response) => {
    if (response.ok) {
      return onSuccess();
    } else {
      const rawResponse = await response.text();
      const error = new Error(`Error submitting form: ${rawResponse}`);
      throw error;
    }
  })
  .catch((error) => {
    trackError(error)
  })
}
