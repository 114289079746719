import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import HeaderMeta from '../components/HeaderMeta';

import {
  ResponsiveMaxWidthFrame,
  SectionGroup,
  TitleBox,
  TextFrame,
  PageNameBox,
  ButtonRow,
  LinkBox,
  HeadingBox,
  BodyBox,
} from "@minware/ui-minware";

const linkRegex = /(?<text>\[[^\]]*?\])(?<link>\([^)]*?\))/g;

function textToLinks(text) {
  const splits = text.split(linkRegex);
  const result = [];
  for (let i = 0; i < splits.length; i++) {
    const curSplit = splits[i];
    if (curSplit[0] === '[') {
      const curLink = splits[++i];
      result.push(<a href={curLink.substring(1, curLink.length-1)} target="blank">
        {curSplit.substring(1, curSplit.length-1)}
      </a>);
    } else {
      result.push(<>{curSplit}</>);
    }
  }
  return result;
}

export const CareersPageTemplate = ({
  header,
  pageName,
  title,
  productDemoHeading,
  productDemoLink,
  ourStoryHeading,
  ourStoryBlocks,
  aboutSections,
  valuesHeading,
  valuesHeaderText,
  valuesList,
  openPositionsHeading,
  openPositionsText,
  positions,
}) => {
  positions = positions ?? [];

  const headerWithCanon = {
    ...header,
    canonicalUrl: '/careers',
  };

  return (
    <Layout>
      <HeaderMeta header={headerWithCanon}/>

      <ResponsiveMaxWidthFrame maxWidth="500px">
        <SectionGroup>
          <PageNameBox>{pageName}</PageNameBox>
          <TitleBox isH2>
            {title}
          </TitleBox>
        </SectionGroup>

        <ButtonRow link="#positions">See Open Positions</ButtonRow>

        <TextFrame
          heading={ourStoryHeading}
          subHeadline={<>
            {ourStoryBlocks.map((text) =>
              <p>{textToLinks(text)}</p>
            )}
          </>}
          outlineBox={true}
        />

      </ResponsiveMaxWidthFrame>

      <SectionGroup>
        {aboutSections.map(({heading, blocks}) =>
          <TextFrame
            heading={heading}
            subHeadline={<>
              {blocks.map((text) =>
                <p>{textToLinks(text)}</p>
              )}
            </>}
            twoColDesktop={true}
          />
        )}
      </SectionGroup>

      <ResponsiveMaxWidthFrame maxWidth="640px">
        <SectionGroup>
          {/* eslint-disable-next-line */}
          <a name="values"/>
          <HeadingBox tagLevel="h2" align="center" justifyContent="center">
            {valuesHeading}
          </HeadingBox>
          <BodyBox large={true}>
            <p>
              {textToLinks(valuesHeaderText)}
            </p>
            <ul>
              {valuesList.map(({name, description}) => <li>
                <b>{name}</b> - {textToLinks(description)}
              </li>)}
            </ul>
          </BodyBox>
        </SectionGroup>
        <SectionGroup>
          {/* eslint-disable-next-line */}
          <a name="positions"/>
          <HeadingBox tagLevel="h2" align="center" justifyContent="center">
            {openPositionsHeading}
          </HeadingBox>
          <BodyBox large={true}>
            {textToLinks(openPositionsText)}
          </BodyBox>
          {positions.map(({department, jobs}) => <>
            <HeadingBox level="h2">{department}</HeadingBox>
            {jobs.map(({link, name}) => (
              <LinkBox link={link} size="large">{name}</LinkBox>
            ))}
          </>)}
        </SectionGroup>
      </ResponsiveMaxWidthFrame>
    </Layout>
  )
}

CareersPageTemplate.propTypes = {
  pretitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const CareersPage = ({ data }) => {
  const { main } = data;

  const post = main.frontmatter;

  const [jobs, setJobs] = useState({departments: []});

  useEffect(() => {
    fetch("https://boards-api.greenhouse.io/v1/boards/minware/departments")
    .then(response => response.json())
    .then(data => setJobs(data))
    .catch((error) => {
      window.newrelic.noticeError(error)
    })
  },[])

  const positions = jobs.departments
      .filter(({jobs}) => jobs.filter(({location: {name}}) => name.includes('Remote')).length > 0)
      .map(({name: department, jobs}) => ({
    department,
    jobs: jobs.filter(({location: {name}}) => name.includes('Remote'))
        .map(({absolute_url, title}) => ({
      link: absolute_url,
      name: title,
    }))
  }));

  return (
    <CareersPageTemplate
      header={main.frontmatter.header}
      positions={positions}
      {...post}
    />
  )
}

CareersPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default CareersPage

export const careersPageQuery = graphql`
  query CareersPage($id: String!) {
    main: markdownRemark(id: { eq: $id }) {
      ...HeaderMeta
      frontmatter {
        pageName
        title
        ourStoryHeading
        ourStoryBlocks
        productDemoHeading
        productDemoLink
        valuesHeading
        aboutSections {
          heading
          blocks
        }
        valuesHeaderText
        valuesList {
          name
          description
        }
        openPositionsHeading
        openPositionsText
      }
    }
  }
`
