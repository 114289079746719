import { Link } from 'gatsby';
import React from 'react';

const LinkType =(props) => {
  const { href, children, className, onClick, name } = props;
  // Allow in-page scrolling links to function normally.
  if (name && !href) {
    return <a {...props}/>;
  } else if (href?.[0] === '#') {
    return <a {...props}/>;
  } else if (href && href.includes('/api/auth/login')) {
    return <a {...props}/>;
  } else {
    return <Link to={href} className={className} onClick={onClick}>{children}</Link>;
  }
};

export default LinkType;
