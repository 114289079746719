import React, { useState, useCallback, useRef, useEffect } from 'react'
import { graphql } from 'gatsby';
import { navigate } from 'gatsby-link'
import Layout from '../components/Layout'
import HeaderMeta from '../components/HeaderMeta';
import { track } from '../analytics';
import { submitForm } from '../lib/submitForm';

import {
  ResponsiveMaxWidthFrame,
  SectionGroup,
  PageNameBox,
  TitleBox,
  Form,
  FormInput,
  FormTextArea,
  FormSubmit,
} from "@minware/ui-minware";

export const ContactPageTemplate = ({
  content,
  confirmPage,
  handleSubmit,
  formName,
  handleChange,
  handleFocus,
  handleBlur,
}) => {
  const headerWithCanon = {
    ...content?.header,
    canonicalUrl: '/contact',
  };

  return (
    <Layout>
      <HeaderMeta header={headerWithCanon}/>

      <ResponsiveMaxWidthFrame maxWidth="500px">
        <SectionGroup>
          <PageNameBox>{content.pageName}</PageNameBox>
          <TitleBox isH2>
            {content.title}
          </TitleBox>
        </SectionGroup>
        <Form
          name="contact"
          method="post"
          action={confirmPage}
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={handleSubmit}
          onFocus={handleFocus}
          onBlur={handleBlur}
        >
          {/* The `form-name` hidden field is required to support form submissions without
              JavaScript */}
          <input type="hidden" name="form-name" value={formName} />
          <div hidden>
            <label>
              Don't fill this out:{' '}
              <input name="bot-field" onChange={handleChange} />
            </label>
          </div>

          <FormInput
            name="name"
            type="text"
            placeholder={content.placeholders.name}
            onChange={handleChange}
            required={true}/>
          <FormInput
            name="email"
            type="email"
            placeholder={content.placeholders.email}
            onChange={handleChange}
            required={true}/>
          <FormInput
            name="organization"
            type="text"
            placeholder={content.placeholders.organization}
            onChange={handleChange}/>
          <FormTextArea
            name="message"
            placeholder={content.placeholders.message}
            onChange={handleChange}/>
          <FormSubmit name="submit" value={content.submitText}/>
        </Form>
      </ResponsiveMaxWidthFrame>
    </Layout>
  );
}

const ContactPage = (props) => {
  const { data } = props;
  const { main } = data;

  const formName = 'contact';

  const [formState, setFormState] = useState({});

  const timeoutRef = useRef();
  // Track changes to the form state after 500ms of no change
  useEffect(() => {
    if (Object.keys(formState).length) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        timeoutRef.current = null;
        track('Contact Page - Form Change', {
          formState
        })
      }, 500);
    }
  }, [formState]);

  const handleChange = useCallback((e) => {
    const formData = new FormData(e.target.form);
    setFormState(Object.fromEntries(formData.entries()));
  }, []);

  const handleFocus = useCallback((e) => {
    track(`Contact Page - Form Focus - ${e.target.name}`, {
      value: e.target.value,
    });
  }, []);

  const handleBlur = useCallback((e) => {
    track(`Contact Page - Form Blur - ${e.target.name}`, {
      value: e.target.value,
    });
  }, []);

  const confirmPage = '/contact/thanks/';

  const handleSubmit = (e) => {
    const formData = new FormData(e.target);
    track('Contact Page - Form Submit', {
      formState: Object.fromEntries(formData.entries()),
    });
    e.preventDefault()
    submitForm(formData, () => { navigate(confirmPage)});
  }

  const content = main.frontmatter;

  return (
    <ContactPageTemplate
      content={content}
      confirmPage={confirmPage}
      handleSubmit={handleSubmit}
      formName={formName}
      handleChange={handleChange}
      handleFocus={handleFocus}
      handleBlur={handleBlur}
    />
  )
};

export default ContactPage;


export const contactPageQuery = graphql`
  query ContactPage($id: String!) {
    main: markdownRemark(id: { eq: $id }) {
      ...HeaderMeta
      frontmatter {
        pageName
        title
        placeholders {
          name
          email
          organization
          message
        }
        submitText
      }
    }
  }
`;
